@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
body{
    margin: 0;
    /* font-family: 'Open Sans', serif; */
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    /* background-color: #fafafa; */
    background-color: white;
}

p{
    font-family: 'Lora', serif;
}
