*{
    margin: 0;
}
html, body {
    height: 100%;
    margin: 0;
  }
  
body {
    margin: 0;
}